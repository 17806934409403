import PropTypes from 'prop-types'

import PageLayout from '@tofu/apps/shop/components/layout/page'
import Sections from '@tofu/apps/shop/components/slices'
import { getTrimmedProducts } from '@tofu/shared/data-access/products'
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { DiscountCheck } from '@tofu/shop/ui/organisms/discount-check'

import {
  client,
  getPromoBannerBySlug,
  getHeaderColourBySlug
} from '@tofu/shop/data-access/prismic'
import { StaticContentProvider } from '@tofu/shop/providers/static-content-provider'
import { buildCarouselProducts } from '@tofu/shop/utils/build-carousel-products'

import { comparisonTableSliceFieldIds } from '../src/components/slices/tables/comparisonTable.constants'

const StaticPages = ({
  page,
  path,
  promoBanner,
  headerColour,
  carouselProducts
}) => (
  <StaticContentProvider
    promoBanner={promoBanner}
    carouselProducts={carouselProducts}
  >
    <PageLayout
      {...page.data}
      path={path}
      headerProps={{
        background: headerColour,
        borderBottom: `1px solid ${
          headerColour === 'white' ? '#d8d8d8' : '#E4C302'
        }`
      }}
    >
      <Sections sections={page.data.body} />
    </PageLayout>
    <DiscountCheck />
  </StaticContentProvider>
)

StaticPages.propTypes = {
  page: PropTypes.shape({
    data: PropTypes.shape({
      body: PropTypes.arrayOf(PropTypes.object).isRequired
    })
  }).isRequired
}

export default StaticPages

export async function getStaticPaths() {
  const documents = await client.getAllByType('single_page')
  const paths = documents.map(({ uid }) => ({
    params: { uid }
  }))

  return {
    paths,
    fallback: 'blocking'
  }
}

export async function getStaticProps({ params }) {
  try {
    const page = await client.getByUID('single_page', params.uid, {
      fetchLinks: [...comparisonTableSliceFieldIds]
    })

    const trimmedProducts = await getTrimmedProducts()
    const carouselProducts = buildCarouselProducts(trimmedProducts)

    const promoBanner = await getPromoBannerBySlug('single_page', params.uid)
    const headerColour =
      (await getHeaderColourBySlug('single_page', params.uid)) || 'white'

    return {
      props: {
        path: `/${params.uid}`,
        page,
        carouselProducts,
        headerColour,
        promoBanner
      },
      revalidate: 60
    }
  } catch {
    return {
      notFound: true
    }
  }
}
